<template>
  <div class="side-members">
    <div class="side-tit">
      <div class="side-tit-left">{{ $t('bbs.member_list') }}</div>
      <!-- 成员列表 -->
    </div>
    <a-spin :spinning="loading">
      <div class="side-members-list">
        <div class="side-members-item" v-for="item in list" :key="item.userId">
          <div class="avatar">
            <a-avatar
              :size="40"
              :src="item.portrait || require(`@/assets/image/bbs/avatar.png`)"
            >
              <OpenData type="userName" :openid="item.realName" />
            </a-avatar>
          </div>
          <div class="text">
            <div class="top">
              <span class="name">
                <OpenData type="userName" :openid="item.realName" />
              </span>
              <tag v-if="item.isMaster === 1" text="圈主" />
              <tag v-if="item.isAdmin === 1" text="管理员" />
            </div>
            <div class="bottom">
              <span class="department">
                <OpenData type="departmentName" :openid="item.departmentName" />
              </span>
              <span>{{ dateFormat(item.joinTime, "YYYY-MM-DD") }}</span>
            </div>
          </div>
        </div>
        <div class="side-page" v-if="totals">
          <a-pagination
            hideOnSinglePage
            size="small"
            simple
            :defaultPageSize="5"
            v-model:current="page"
            :total="totals"
            @change="pageChange"
          />
        </div>
        <a-empty v-if="!totals" />
      </div>
    </a-spin>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { dateFormat } from "@/utils/tools";
import { getGroupMembersList } from "@/api/bbs";
import tag from "./tag.vue";
import OpenData from "@/components/OpenData.vue";
export default {
  name: "sideMembers",
  components: {
    tag,
    OpenData,
  },
  props: {
    groupId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const state = reactive({
      loading: false,
      page: 1,
      list: [],
      totals: 0,
    });

    const getList = () => {
      state.loading = true;
      getGroupMembersList(props.groupId, {
        page: state.page,
        pageSize: 5,
        state: 1,
      }).then((res) => {
        state.loading = false;
        state.list = res.data.list || [];
        state.totals = res.data.totals || 0;
      });
    };
    getList();

    const pageChange = (n) => {
      state.page = n;
      getList();
    };

    return {
      dateFormat,
      ...toRefs(state),
      pageChange,
    };
  },
};
</script>

<style lang="less" scoped>
.side-tit {
  padding: 15px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  line-height: 24px;
  .mixinFlex(space-between);
  &-left {
    font-size: 16px;
    color: #202020;
  }
  &-right {
    a {
      font-size: 14px;
      color: #666666;
      transition: color 0.3s;
      &:hover {
        color: @color-theme;
      }
    }
  }
}
.side-members {
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  &-list {
    padding: 15px 20px;
  }
  &-item {
    margin-bottom: 15px;
    .mixinFlex(space-between; center);
    &:last-child {
      margin-bottom: 0;
    }
    .avatar {
      width: 40px;
      height: 40px;
    }
    .text {
      width: calc(100% - 48px);
      .top {
        font-size: 14px;
        .mixinFlex(flex-start; center);
        .name {
          color: #333;
          margin-right: 5px;
          max-width: 100px;
          .mixinEllipsis(22px);
        }
      }
      .bottom {
        .mixinFlex(space-between; center);
        font-size: 12px;
        color: #666;
        .department {
          max-width: 100px;
          .mixinEllipsis(20px);
        }
      }
    }
  }
}
.side-page {
  text-align: center;
  ::v-deep(.ant-pagination) {
    .ant-pagination-item-link {
      background-color: #f3f3f3;
      border-radius: 50%;
    }
    .ant-pagination-simple-pager {
      margin-right: 20px;
      input {
        border: none;
        padding: 0;
        margin: 0;
        text-align: right;
        pointer-events: none;
      }
    }
    .ant-pagination-slash {
      margin: 0 2px;
    }
  }
}
</style>
