<template>
  <a-tag :style="{ color: color }" :color="bgColor">{{ text }}</a-tag>
</template>

<script>
import { reactive, toRefs } from "vue";
export default {
  name: "tag",
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const state = reactive({
      color: "",
      bgColor: "",
    });

    if (props.text === "置顶") {
      // 置顶
      state.color = "#FF4D4F";
      state.bgColor = "#FFF1F0";
    } else if (props.text === "精华") {
      // 精华
      state.color = "#597EF7";
      state.bgColor = "#D6E4FF";
    } else if (props.text === "文章") {
      // 文章
      state.color = "#ff7a45";
      state.bgColor = "#FFF2E8";
    } else if (props.text === "问答") {
      // 问答
      state.color = "#9254DE";
      state.bgColor = "#F9F0FF";
    } else if (
      props.text === "管理员" ||
      props.text === "我管理的" ||
      props.text === "圈主" ||
      props.text === "我创建的"
    ) {
      // 管理员 我管理的 圈主 我创建的
      state.color = "#848484";
      state.bgColor = "#f0f0f0";
    } else if (props.text.indexOf("悬赏") !== -1) {
      state.color = "#FFA940";
      state.bgColor = "#FFF7E6";
    }

    return {
      ...toRefs(state),
    };
  },
};
</script>
